var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("e-sidebar", {
        attrs: {
          title: _vm.$t("Novo Comando"),
          show: _vm.showSidebar,
          fetching: _vm.fetching,
          saving: _vm.saving,
          width: "500px",
        },
        on: {
          save: _vm.createCommand,
          hidden: function ($event) {
            _vm.showSidebar = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "FormulateForm",
                  {
                    ref: "payBoxCommandForm",
                    attrs: { name: "payBoxCommandForm" },
                  },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "6" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "manage_pay_box_command_sidebar-broadcast",
                                name: "broadcast",
                                type: "switch",
                                label: _vm.$t("Realizar Broadcast?"),
                              },
                              model: {
                                value: _vm.payBoxCommand.broadcast,
                                callback: function ($$v) {
                                  _vm.$set(_vm.payBoxCommand, "broadcast", $$v)
                                },
                                expression: "payBoxCommand.broadcast",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            !_vm.payBoxCommand.broadcast
                              ? _c("e-store-combo", {
                                  attrs: {
                                    id: "manage_pay_box_command_sidebar-storeId",
                                    required: "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.storeChanged()
                                    },
                                  },
                                  model: {
                                    value: _vm.payBoxCommand.storeId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.payBoxCommand,
                                        "storeId",
                                        $$v
                                      )
                                    },
                                    expression: "payBoxCommand.storeId",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            !_vm.payBoxCommand.broadcast
                              ? _c("FormulateInput", {
                                  staticClass: "required",
                                  attrs: {
                                    id: "manage_pay_box_command_sidebar-payboxId",
                                    type: "vue-select",
                                    label: _vm.$t("PDV"),
                                    placeholder: _vm.$t("Selecione"),
                                    validation: "required",
                                    options: _vm.payBoxesOptions,
                                  },
                                  model: {
                                    value: _vm.payBoxCommand.payboxId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.payBoxCommand,
                                        "payboxId",
                                        $$v
                                      )
                                    },
                                    expression: "payBoxCommand.payboxId",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            _c("FormulateInput", {
                              staticClass: "required",
                              attrs: {
                                id: "manage_pay_box_command_sidebar-command",
                                type: "vue-select",
                                label: _vm.$t("Comando"),
                                placeholder: _vm.$t("Selecione"),
                                validation: "required",
                                options: _vm.payBoxCommandsOptions(),
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.onChangeCommand()
                                },
                              },
                              model: {
                                value: _vm.payBoxCommand.command,
                                callback: function ($$v) {
                                  _vm.$set(_vm.payBoxCommand, "command", $$v)
                                },
                                expression: "payBoxCommand.command",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "manage_pay_box_command_sidebar-requires-user-confirmation",
                                type: "switch",
                                label: _vm.$t("Requer Confirmação do Usuário?"),
                                instruction: _vm.$t(
                                  "Indica se o comando requer confirmação do usuário"
                                ),
                              },
                              on: {
                                change: function (val) {
                                  return _vm.onUpdateRequiresUserConfirmation(
                                    val
                                  )
                                },
                              },
                              model: {
                                value:
                                  _vm.payBoxCommand.requiresUserConfirmation,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.payBoxCommand,
                                    "requiresUserConfirmation",
                                    $$v
                                  )
                                },
                                expression:
                                  "payBoxCommand.requiresUserConfirmation",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            _vm.payBoxCommand.requiresUserConfirmation
                              ? _c("FormulateInput", {
                                  attrs: {
                                    id: "manage_pay_box_command_sidebar-message-confirmation",
                                    type: "textarea",
                                    label: _vm.$t("Mensagem de Confirmação"),
                                  },
                                  model: {
                                    value:
                                      _vm.payBoxCommand.messageConfirmation,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.payBoxCommand,
                                        "messageConfirmation",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "payBoxCommand.messageConfirmation",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }