<template>
  <div>
    <e-sidebar
      :title="$t('Novo Comando')"
      :show="showSidebar"
      :fetching="fetching"
      :saving="saving"
      width="500px"
      @save="createCommand"
      @hidden="showSidebar = false"
    >
      <template #content>
        <FormulateForm
          ref="payBoxCommandForm"
          name="payBoxCommandForm"
        >
          <b-row>
            <b-col md="6">
              <FormulateInput
                id="manage_pay_box_command_sidebar-broadcast"
                v-model="payBoxCommand.broadcast"
                name="broadcast"
                type="switch"
                :label="$t('Realizar Broadcast?')"
              />
            </b-col>
            <b-col md="12">
              <e-store-combo
                v-if="!payBoxCommand.broadcast"
                id="manage_pay_box_command_sidebar-storeId"
                v-model="payBoxCommand.storeId"
                required
                @input="storeChanged()"
              />
            </b-col>

            <b-col md="12">
              <FormulateInput
                v-if="!payBoxCommand.broadcast"
                id="manage_pay_box_command_sidebar-payboxId"
                v-model="payBoxCommand.payboxId"
                type="vue-select"
                class="required"
                :label="$t('PDV')"
                :placeholder="$t('Selecione')"
                validation="required"
                :options="payBoxesOptions"
              />
            </b-col>

            <b-col md="12">
              <FormulateInput
                id="manage_pay_box_command_sidebar-command"
                v-model="payBoxCommand.command"
                type="vue-select"
                class="required"
                :label="$t('Comando')"
                :placeholder="$t('Selecione')"
                validation="required"
                :options="payBoxCommandsOptions()"
                @input="onChangeCommand()"
              />
            </b-col>

            <b-col md="12">
              <FormulateInput
                id="manage_pay_box_command_sidebar-requires-user-confirmation"
                v-model="payBoxCommand.requiresUserConfirmation"
                type="switch"
                :label="$t('Requer Confirmação do Usuário?')"
                :instruction="$t('Indica se o comando requer confirmação do usuário')"
                @change="(val) => onUpdateRequiresUserConfirmation(val)"
              />
            </b-col>

            <b-col md="12">
              <FormulateInput
                v-if="payBoxCommand.requiresUserConfirmation"
                id="manage_pay_box_command_sidebar-message-confirmation"
                v-model="payBoxCommand.messageConfirmation"
                type="textarea"
                :label="$t('Mensagem de Confirmação')"
              />
            </b-col>
          </b-row>
        </FormulateForm>
      </template>
    </e-sidebar>
  </div>
</template>


<script>
// import _ from 'lodash'
import { BRow, BCol } from 'bootstrap-vue'
import { ESidebar } from '@/views/components'
import { uploader, payBoxCommandDomain } from '@/mixins'
import { mapActions, mapGetters, mapState } from 'vuex'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'

export default {
  components: {
    ESidebar,
    BRow,
    BCol,
    EStoreCombo,
  },

  mixins: [uploader, payBoxCommandDomain],

  data() {
    return {
      showSidebar: false,
      fetching: false,
      saving: false,
    }
  },

  computed: {
    ...mapState('pages/payboxCommands/managePayBoxCommands', ['payBoxCommand']),
    ...mapGetters('pages/payboxCommands/managePayBoxCommands', ['payBoxesOptions', 'commandsOptions']),
  },

  methods: {
    ...mapActions('pages/payboxCommands/managePayBoxCommands', ['fetchPayBoxes', 'setTemplateMessageConfirmation']),

    showCreate() {
      this.showSidebar = true
    },

    storeChanged() {
      if (this.payBoxCommand.storeId) {
        this.$nextTick(async () => {
          try {
            this.fetching = true
            await this.fetchPayBoxes(this.payBoxCommand.storeId)
          } catch (error) {
            this.showError({ error })
          } finally {
            this.fetching = false
          }
        })
      }
    },

    async onChangeCommand(){
      if(this.payBoxCommand.requiresUserConfirmation){
        this.setMessageConfirmationTemplate()
      }
    },

    async onUpdateRequiresUserConfirmation(value){
      if(value){
        this.setMessageConfirmationTemplate()
      }else{
        this.payBoxCommand.messageConfirmation = ''
      }
    },

    async setMessageConfirmationTemplate(){
      this.payBoxCommand.messageConfirmation = `Permissao para executar o comando: ${  this.payBoxCommandsMessageConfirmationTemplate[this.payBoxCommand.command]}`
    },

    async createCommand() {
      this.$refs.payBoxCommandForm.showErrors()
      if (this.$refs.payBoxCommandForm.hasErrors) {
        this.showInvalidDataMessage()
        return
      }

      try {
        this.saving = true

        await this.$http({
          url: '/api/sales/pay-box/command',
          method: 'POST',
          data: this.payBoxCommand,
        })

        this.showSuccess({
          message: this.$t(`Inclusão realizada com sucesso`),
        })
        this.showSidebar = false
        this.$emit('after-save')
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },
  },
}
</script>

<style></style>
